import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, useLocation, RouteProps, RouteComponentProps, useHistory } from 'react-router-dom'
import loadable from '@loadable/component'
import { Box, CircularProgress } from '@material-ui/core'
import { useShortLink } from 'src/hooks/useShortLink'
import { GTM_EVENT } from 'src/interfaces/gtm'
import { RouteParams as RouteParams404Page } from 'src/pages/types/404'
import { RouteParams as RouteParamsParCreationPage } from 'src/pages/types/participantCreation'
import { RouteParams as RouteParamsParEditingPage } from 'src/pages/types/participantEditing'
import { RootState } from '../store'
import { RegistrantState } from '../store/modules/registrant/types'
import { useGtm } from '../hooks'
import { Utils } from '../lib/utils'
import rollbar from '../lib/rollbar'

const ParticipantCreation = loadable(() => import('../pages/ParticipantCreation'))
const PageNotFound = loadable(() => import('../pages/404'))
const RegistrantRegistration = loadable(() => import('../pages/RegistrantRegistration'))
const RegistrantAuthorization = loadable(() => import('../pages/RegistrantAuthorization'))
const RegistrantSignIn = loadable(() => import('../pages/RegistrantSignIn'))
const ChangingOrganization = loadable(() => import('../pages/ChangingOrganization'))
const ParticipantList = loadable(() => import('../pages/ParticipantList'))
const ParticipantSingle = loadable(() => import('../pages/ParticipantSingle'))
const ParticipantEditing = loadable(() => import('../pages/ParticipantEditing'))
const SendInvitations = loadable(() => import('../pages/SendingInvitations'))
const Gateway = loadable(() => import('../pages/Gateway'))
const RegistrantEditing = loadable(() => import('../pages/RegistrantEditing'))
const PrivacyPolicy = loadable(() => import('../pages/PrivacyPolicy'))
const TermsOfService = loadable(() => import('../pages/TermsOfService'))
const Troubleshooting = loadable(() => import('../pages/Troubleshooting'))

const commonRoutes: RouteProps[] = [
  {
    path: '/',
    exact: true,
    render: (props) => {
      document.title = 'Charleston Wrap - HUB'
      return <RegistrantRegistration {...props} />
    },
  },
  {
    path: '/registrant/registration',
    render: (props) => {
      document.title = 'Charleston Wrap - HUB'
      return <RegistrantRegistration {...props} />
    },
  },
  {
    path: '/registrant/sign-in',
    render: (props) => {
      document.title = 'Charleston Wrap - Registrant Sign In'
      return <RegistrantSignIn {...props} />
    },
  },
  {
    path: '/registrant/authorization',
    render: (props) => {
      document.title = 'Charleston Wrap - Registrant Authorization'
      return <RegistrantAuthorization {...props} />
    },
  },
  {
    path: '/gateway',
    render: (props) => {
      const token = Utils.getQueryParamByName(props.location.search, 'token')
      document.title = `Charleston Wrap - ${token ? 'Gateway' : 'Guest Gateway'}`
      return <Gateway {...props} />
    },
  },
  {
    path: '/privacy-policy',
    render: (props) => {
      document.title = 'Charleston Wrap - Privacy Policy'
      return <PrivacyPolicy {...props} />
    },
  },
  {
    path: '/terms-of-service',
    render: (props) => {
      document.title = 'Charleston Wrap - Terms of Service'
      return <TermsOfService {...props} />
    },
  },
]

const pageNotFound: RouteProps = {
  path: '*',
  render: (props) => {
    document.title = 'Charleston Wrap - 404 - Page Not Found'
    return <PageNotFound {...(props as RouteComponentProps<{}, { statusCode?: number }, RouteParams404Page>)} />
  },
}

const authorizedRoutes: RouteProps[] = [
  {
    path: '/registrant/change-organization',
    render: (props) => {
      document.title = 'Charleston Wrap - List Of Organization'
      return <ChangingOrganization {...props} />
    },
  },
  {
    path: '/registrant/editing',
    render: (props) => {
      document.title = 'Charleston Wrap - Profile'
      return <RegistrantEditing {...props} />
    },
  },
  {
    path: '/participant/creation',
    render: (props) => {
      document.title = 'Charleston Wrap - Participant Registration'
      return (
        <ParticipantCreation
          {...(props as RouteComponentProps<{}, { statusCode?: number }, RouteParamsParCreationPage>)}
        />
      )
    },
  },
  {
    path: '/participant/list',
    render: (props) => {
      document.title = "Charleston Wrap - Organization's Dashboard"
      return <ParticipantList {...props} />
    },
  },
  {
    path: '/participant/editing',
    render: (props) => {
      document.title = 'Charleston Wrap - Participant Editing'
      return (
        <ParticipantEditing
          {...(props as RouteComponentProps<{}, { statusCode?: number }, RouteParamsParEditingPage>)}
        />
      )
    },
  },
  {
    path: '/participant/single',
    render: (props) => {
      document.title = "Charleston Wrap - Participant's Dashboard"
      return <ParticipantSingle {...props} />
    },
  },
  {
    path: '/invites/sending/email',
    render: (props) => {
      document.title = 'Charleston Wrap - Sending Invitations - email'
      return <SendInvitations {...props} />
    },
  },
  {
    path: '/invites/sending/text',
    render: (props) => {
      document.title = 'Charleston Wrap - Sending Invitations - text'
      return <SendInvitations {...props} />
    },
  },
  {
    path: '/troubleshooting',
    render: (props) => {
      document.title = 'Charleston Wrap - Troubleshooting'
      return <Troubleshooting {...props} />
    },
  },
]

const AppRouter: React.FC = () => {
  const { token, registrant, seasonOrganization } = useSelector<RootState, RegistrantState>((state) => state.registrant)
  const [loadingLinkData, setLoadingLinkData] = useState<boolean>(true)
  const { findLink, startsWithHttp } = useShortLink()
  const { sendDataToGTM } = useGtm()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      if (!isRouteIncludedAt(location.pathname, [...authorizedRoutes, ...commonRoutes])) {
        await loadLinkData()
      } else {
        if (!token && isRouteIncludedAt(location.pathname, authorizedRoutes)) {
          history.replace('/')
        }
        setLoadingLinkData(false)
      }
    })()
  }, [])

  useEffect(() => {
    try {
      sendDataToGTM({ event: GTM_EVENT.PAGE_VIEW })
    } catch (e) {
      rollbar.error('Caught GTM error', e as string)
    }
  }, [location, registrant, seasonOrganization])

  const isRouteIncludedAt = (path: string, routes: RouteProps[]): boolean => routes.some((route) => route.path === path)

  const loadLinkData = useCallback(async () => {
    setLoadingLinkData(true)
    const result = await findLink(location.pathname)
    if (result !== null && startsWithHttp(result)) {
      window.location.replace(result)
    } else if (result !== null) {
      history.replace(result)
    }
    setLoadingLinkData(false)
  }, [location, findLink])

  if (loadingLinkData) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  if (!token) {
    return (
      <Switch>
        {[...commonRoutes, pageNotFound].map((route, index) => (
          <Route key={index} path={route.path} exact={route.exact} render={route.render} />
        ))}
      </Switch>
    )
  }

  return (
    <Switch>
      {[...authorizedRoutes, ...commonRoutes, pageNotFound].map((route, index) => (
        <Route key={index} path={route.path} exact={route.exact} render={route.render} />
      ))}
    </Switch>
  )
}

export default AppRouter
