import canvasSize from 'canvas-size'
import { isMobileOnly } from 'react-device-detect'

// In Safari canvas has size limitation
// To avoid error we need to check max width and height of it
// Error: Canvas area exceeds the maximum limit (width * height > 16777216)

export declare interface Area {
  width: number
  height: number
  x: number
  y: number
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    // needed to avoid cross-origin issues on CodeSandbox
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

const getRadianAngle = (degreeValue: number) => (degreeValue * Math.PI) / 180

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc: string, pixelCrop: Area, rotation = 0): Promise<Blob> {
  // https://github.com/jhildenbiddle/canvas-size/issues/13#issuecomment-827107515
  // https://jhildenbiddle.github.io/canvas-size/#/?id=mobile
  const { width: maxWidth, height: maxHeight } = await canvasSize.maxArea({
    max: isMobileOnly ? 8192 : null, // using 8,192, because it still crashes on 16,384
  })
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

  const max = Math.max(maxWidth, maxHeight)
  const maxSize = Math.max(image.width, image.height)
  const safe = 2 * ((maxSize / 2) * Math.sqrt(2))
  const safeArea = safe > max ? max : safe

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // Add background color for png width transparent
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, safeArea, safeArea)

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg')

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (file) => {
        if (file === null) reject(new Error('File cannot be cropped.'))
        resolve(file as Blob)
      },
      'image/jpeg',
      0.9,
    )
  })
}
